import React from "react"
import Layout from "../components/layout"
import Footer from "../components/footer"
import bodyStyles from "../styles/services.module.scss"
import personnage from "../Images/SVG_web_illustrations/DTT_Bonhomme_coeur.svg"
import doctoctoc from "../Images/DocTocToc_logos/DocTocToc_logo_couleur.svg"
import { useTranslation } from "../hooks/useTranslation"

const ServicesPage = () => {

    const t = useTranslation();

    return (
        <div>
            <Layout>
                <div className={bodyStyles.body}>

                    <h1>{t("services.services")}</h1>

                    <img src={personnage} className={bodyStyles.purple_guy} alt="purple guy"/>

                    <p>
                        <img src={doctoctoc} className={bodyStyles.logo}/> {t("services.paragraph_one")}
                        <br/>
                        <br/>
                        {t("services.paragraph_two")}
                    </p>
                </div>
            </Layout>

            <Footer color="#b64b85" />
        </div>

    )
}

export default ServicesPage